var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('the-logo'),_c('h2',{staticClass:"brand-text text-primary ml-1"})],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Login V2"}})],1)]),_c('b-col',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"d-flex align-items-center auth-bg px-2",attrs:{"lg":"4"}},[_c('transition',{attrs:{"name":"loginBox"}},[_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isRegister),expression:"!isRegister"}],staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" 个人信息合规管理平台 ")]),_c('b-card-text',{staticClass:"mb-2"}),_c('validation-observer',{ref:"loginValidation"},[_c('b-form',{ref:"loginForm",staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',{attrs:{"label":"账号","label-for":"login-email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"login-email","state":errors.length > 0 ? false : null,"name":"login-email","placeholder":"john@example.com"},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v("请输入账号")])]}}])})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"login-password"}},[_vm._v("密码")]),_c('b-link',{attrs:{"to":{ name: 'auth-forgot-password-v2' }}},[_c('small',[_vm._v("忘记密码?")])])],1),_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-password","state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType,"name":"login-password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v("请输入密码")])]}}])})],1),_c('b-form-group',{attrs:{"label":"验证码","label-for":"login-email"}},[_c('validation-provider',{attrs:{"name":"verifyCode","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"flex-start"},[_c('b-form-input',{attrs:{"id":"login-verifyCode","state":errors.length > 0 ? false : null,"name":"login-verifyCode"},model:{value:(_vm.verifyCode),callback:function ($$v) {_vm.verifyCode=$$v},expression:"verifyCode"}}),_c('img',{staticClass:"verifycode",attrs:{"src":_vm.imgSrc},on:{"click":_vm.getVerifyCode}})],1),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v("请输入验证码")])]}}])})],1),_c('b-row',{attrs:{"align-h":"center"}},[_c('b-button',{attrs:{"type":"submit","variant":"primary"},on:{"click":_vm.validationForm}},[_vm._v(" 登录 ")]),_c('b-button',{staticClass:"goLogin",attrs:{"variant":"link"},on:{"click":function($event){_vm.isRegister= true}}},[_vm._v(" 快速注册 ")])],1)],1)],1)],1)],1),_c('transition',{attrs:{"name":"registerBox"}},[_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRegister),expression:"isRegister"}],staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" 企业注册 ")]),_c('b-card-text',{staticClass:"mb-2"}),_c('validation-observer',{ref:"registerValidation"},[_c('b-form',{ref:"registerForm",staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',[_c('i',{staticClass:"required-group"},[_vm._v("*")]),_c('b-form-group',{attrs:{"label":"账号","label-cols":"2","label-align":"left","label-for":"register-account"}},[_c('validation-provider',{attrs:{"name":"账号","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-account","state":errors.length > 0 ? false : null,"name":"register-account","placeholder":"john@example.com"},model:{value:(_vm.account),callback:function ($$v) {_vm.account=$$v},expression:"account"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('i',{staticClass:"required-group"},[_vm._v("*")]),_c('b-form-group',{attrs:{"label":"姓名","label-cols":"2","label-align":"left","label-for":"register-name"}},[_c('validation-provider',{attrs:{"name":"姓名","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-name","state":errors.length > 0 ? false : null,"name":"register-name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('i',{staticClass:"required-group"},[_vm._v("*")]),_c('b-form-group',{attrs:{"label":"密码","label-cols":"2","label-align":"left","label-for":"register-password"}},[_c('validation-provider',{attrs:{"name":"密码","rules":"required|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","state":errors.length > 0 ? false : null,"name":"register-password","type":"password","placeholder":"············"},model:{value:(_vm.registerPswd),callback:function ($$v) {_vm.registerPswd=$$v},expression:"registerPswd"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('i',{staticClass:"required-group"},[_vm._v("*")]),_c('b-form-group',{attrs:{"label":"重输密码","label-cols":"2","label-align":"left","label-for":"register-rePassword"}},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-rePassword","state":_vm.errorsPwd ? false : null,"name":"register-rePassword","type":"password","placeholder":"············"},on:{"blur":_vm.rePasswordBlur,"input":_vm.rePasswordBlur},model:{value:(_vm.rePassword),callback:function ($$v) {_vm.rePassword=$$v},expression:"rePassword"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(_vm.errorsPwd),expression:"errorsPwd"}],staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errorsPwd))])],1)],1)],1),_c('b-row',[_c('b-col',[_c('i',{staticClass:"required-group"},[_vm._v("*")]),_c('b-form-group',{attrs:{"label":"手机号码","label-cols":"2","label-align":"left","label-for":"register-phone"}},[_c('validation-provider',{attrs:{"name":"手机号码","rules":"required|mobile"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-phone","state":errors.length > 0 ? false : null,"name":"register-phone"},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('i',{staticClass:"required-group"},[_vm._v("*")]),_c('b-form-group',{attrs:{"label":"验证码","label-cols":"4","label-align":"left","label-for":"register-phoneCode"}},[_c('validation-provider',{attrs:{"name":"验证码","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-phoneCode","state":errors.length > 0 ? false : null,"name":"register-phoneCode"},model:{value:(_vm.phoneCode),callback:function ($$v) {_vm.phoneCode=$$v},expression:"phoneCode"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('b-button',{attrs:{"variant":"primary","block":"","disabled":_vm.codeFlag},on:{"click":_vm.getPhoneCode}},[_vm._v(" "+_vm._s(_vm.codeText)+" ")])],1)],1),_c('b-row',[_c('b-col',[_c('i',{staticClass:"required-group"},[_vm._v("*")]),_c('b-form-group',{attrs:{"label":"企业名称","label-cols":"2","label-align":"left","label-for":"register-corpname"}},[_c('validation-provider',{attrs:{"name":"企业名称","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-corpname","state":errors.length > 0 ? false : null,"name":"register-corpname"},model:{value:(_vm.corpname),callback:function ($$v) {_vm.corpname=$$v},expression:"corpname"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('i',{staticClass:"required-group"},[_vm._v("*")]),_c('b-form-group',{attrs:{"label":"电子邮箱","label-cols":"2","label-align":"left","label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"电子邮箱","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","state":errors.length > 0 ? false : null,"name":"register-email"},model:{value:(_vm.corpEmail),callback:function ($$v) {_vm.corpEmail=$$v},expression:"corpEmail"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"办公地址","label-cols":"2","label-align":"left","label-for":"register-address"}},[_c('b-form-input',{attrs:{"id":"register-address","name":"register-address"},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})],1)],1)],1),_c('b-row',{attrs:{"align-h":"center"}},[_c('b-button',{attrs:{"type":"submit","variant":"primary"},on:{"click":_vm.validRegisterForm}},[_vm._v(" 注册 ")]),_c('b-button',{staticClass:"goLogin",attrs:{"variant":"link"},on:{"click":function($event){_vm.isRegister= false}}},[_vm._v(" 已有账号，前去登录 ")])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }