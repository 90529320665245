<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <the-logo />
        <h2 class="brand-text text-primary ml-1" />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8"
             class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid
                 :src="imgUrl"
                 alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col v-loading="loading"
             lg="4"
             class="d-flex align-items-center auth-bg px-2">
        <transition name="loginBox">
          <b-col sm="8"
                 md="6"
                 lg="12"
                 v-show="!isRegister"
                 class="px-xl-2 mx-auto">
            <b-card-title title-tag="h2"
                          class="font-weight-bold mb-1">
              个人信息合规管理平台
            </b-card-title>
            <b-card-text class="mb-2" />

            <!-- form -->
            <validation-observer ref="loginValidation">
              <b-form ref="loginForm"
                      class="auth-login-form mt-2"
                      @submit.prevent>
                <!-- email -->
                <b-form-group label="账号"
                              label-for="login-email">
                  <validation-provider #default="{ errors }"
                                       name="Email"
                                       rules="required">
                    <b-form-input id="login-email"
                                  v-model="userEmail"
                                  :state="errors.length > 0 ? false : null"
                                  name="login-email"
                                  placeholder="john@example.com" />
                    <small v-show="errors[0]"
                           class="text-danger">请输入账号</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">密码</label>
                    <b-link :to="{ name: 'auth-forgot-password-v2' }">
                      <small>忘记密码?</small>
                    </b-link>
                  </div>
                  <validation-provider #default="{ errors }"
                                       name="Password"
                                       rules="required">
                    <b-input-group class="input-group-merge"
                                   :class="errors.length > 0 ? 'is-invalid' : null">
                      <b-form-input id="login-password"
                                    v-model="password"
                                    :state="errors.length > 0 ? false : null"
                                    class="form-control-merge"
                                    :type="passwordFieldType"
                                    name="login-password"
                                    placeholder="············" />
                      <b-input-group-append is-text>
                        <feather-icon class="cursor-pointer"
                                      :icon="passwordToggleIcon"
                                      @click="togglePasswordVisibility" />
                      </b-input-group-append>
                    </b-input-group>
                    <small v-show="errors[0]"
                           class="text-danger">请输入密码</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group label="验证码"
                              label-for="login-email">
                  <validation-provider #default="{ errors }"
                                       name="verifyCode"
                                       rules="required">
                    <div class="flex-start">
                      <b-form-input id="login-verifyCode"
                                    v-model="verifyCode"
                                    :state="errors.length > 0 ? false : null"
                                    name="login-verifyCode" />
                      <img :src="imgSrc"
                           class="verifycode"
                           @click="getVerifyCode">
                    </div>
                    <small v-show="errors[0]"
                           class="text-danger">请输入验证码</small>
                  </validation-provider>
                </b-form-group>
                <!-- checkbox -->
                <!--              <b-form-group>-->
                <!--                <b-form-checkbox-->
                <!--                  id="remember-me"-->
                <!--                  v-model="remember"-->
                <!--                  name="checkbox-1"-->
                <!--                >-->
                <!--                  记住我-->
                <!--                </b-form-checkbox>-->
                <!--              </b-form-group>-->

                <!-- submit buttons -->
                <b-row align-h="center">
                  <b-button type="submit"
                            variant="primary"
                            @click="validationForm">
                    登录
                  </b-button>
                  <b-button variant="link"
                            class="goLogin"
                            @click="isRegister= true">
                    快速注册
                  </b-button>
                </b-row>
              </b-form>
            </validation-observer>

          </b-col>
        </transition>
        <transition name="registerBox">
          <b-col sm="8"
                 md="6"
                 lg="12"
                 v-show="isRegister"
                 class="px-xl-2 mx-auto">
            <b-card-title title-tag="h2"
                          class="font-weight-bold mb-1">
              企业注册
            </b-card-title>
            <b-card-text class="mb-2" />

            <!-- form -->
            <validation-observer ref="registerValidation">
              <b-form ref="registerForm"
                      class="auth-login-form mt-2"
                      @submit.prevent>
                <b-row>
                  <b-col>
                    <i class="required-group">*</i>
                    <b-form-group label="账号"
                                  label-cols="2"
                                  label-align="left"
                                  label-for="register-account">

                      <validation-provider #default="{ errors }"
                                           name="账号"
                                           rules="required">
                        <b-form-input id="register-account"
                                      v-model="account"
                                      :state="errors.length > 0 ? false : null"
                                      name="register-account"
                                      placeholder="john@example.com" />
                        <small v-show="errors[0]"
                               class="text-danger">{{errors[0]}}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <i class="required-group">*</i>
                    <b-form-group label="姓名"
                                  label-cols="2"
                                  label-align="left"
                                  label-for="register-name">
                      <validation-provider #default="{ errors }"
                                           name="姓名"
                                           rules="required">
                        <b-form-input id="register-name"
                                      v-model="name"
                                      :state="errors.length > 0 ? false : null"
                                      name="register-name" />
                        <small v-show="errors[0]"
                               class="text-danger">{{errors[0]}}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <i class="required-group">*</i>
                    <b-form-group label="密码"
                                  label-cols="2"
                                  label-align="left"
                                  label-for="register-password">
                      <validation-provider #default="{ errors }"
                                           name="密码"
                                           rules="required|password">
                        <b-form-input id="register-password"
                                      v-model="registerPswd"
                                      :state="errors.length > 0 ? false : null"
                                      class="form-control-merge"
                                      name="register-password"
                                      type="password"
                                      placeholder="············" />
                        <small v-show="errors[0]"
                               class="text-danger">{{errors[0]}}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <i class="required-group">*</i>
                    <b-form-group label="重输密码"
                                  label-cols="2"
                                  label-align="left"
                                  label-for="register-rePassword">
                      <b-form-input id="register-rePassword"
                                    v-model="rePassword"
                                    :state="errorsPwd ? false : null"
                                    class="form-control-merge"
                                    name="register-rePassword"
                                    type="password"
                                    @blur="rePasswordBlur"
                                    @input="rePasswordBlur"
                                    placeholder="············" />
                      <small v-show="errorsPwd"
                             class="text-danger">{{errorsPwd}}</small>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <i class="required-group">*</i>
                    <b-form-group label="手机号码"
                                  label-cols="2"
                                  label-align="left"
                                  label-for="register-phone">
                      <validation-provider #default="{ errors }"
                                           name="手机号码"
                                           rules="required|mobile">
                        <b-form-input id="register-phone"
                                      v-model="phoneNumber"
                                      :state="errors.length > 0 ? false : null"
                                      name="register-phone" />
                        <small v-show="errors[0]"
                               class="text-danger">{{errors[0]}}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <i class="required-group">*</i>
                    <b-form-group label="验证码"
                                  label-cols="4"
                                  label-align="left"
                                  label-for="register-phoneCode">
                      <validation-provider #default="{ errors }"
                                           name="验证码"
                                           rules="required">
                        <b-form-input id="register-phoneCode"
                                      v-model="phoneCode"
                                      :state="errors.length > 0 ? false : null"
                                      class="form-control-merge"
                                      name="register-phoneCode" />
                        <small v-show="errors[0]"
                               class="text-danger">{{errors[0]}}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-button variant="primary"
                              block
                              :disabled="codeFlag"
                              @click="getPhoneCode">
                      {{codeText}}
                    </b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <i class="required-group">*</i>
                    <b-form-group label="企业名称"
                                  label-cols="2"
                                  label-align="left"
                                  label-for="register-corpname">
                      <validation-provider #default="{ errors }"
                                           name="企业名称"
                                           rules="required">
                        <b-form-input id="register-corpname"
                                      v-model="corpname"
                                      :state="errors.length > 0 ? false : null"
                                      name="register-corpname" />
                        <small v-show="errors[0]"
                               class="text-danger">{{errors[0]}}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <i class="required-group">*</i>
                    <b-form-group label="电子邮箱"
                                  label-cols="2"
                                  label-align="left"
                                  label-for="register-email">
                      <validation-provider #default="{ errors }"
                                           name="电子邮箱"
                                           rules="required|email">
                        <b-form-input id="register-email"
                                      v-model="corpEmail"
                                      :state="errors.length > 0 ? false : null"
                                      name="register-email" />
                        <small v-show="errors[0]"
                               class="text-danger">{{errors[0]}}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group label="办公地址"
                                  label-cols="2"
                                  label-align="left"
                                  label-for="register-address">
                      <b-form-input id="register-address"
                                    v-model="address"
                                    name="register-address" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row align-h="center">
                  <b-button type="submit"
                            variant="primary"
                            @click="validRegisterForm">
                    注册
                  </b-button>
                  <b-button variant="link"
                            class="goLogin"
                            @click="isRegister= false">
                    已有账号，前去登录
                  </b-button>
                </b-row>
              </b-form>
            </validation-observer>

          </b-col>
        </transition>

      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import theLogo from '@core/layouts/components/LogoPICaaS.vue'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormFile,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt'
import { required, email, mobile, password } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
// eslint-disable-next-line import/no-cycle
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import * as userData from 'vee-validate/dist/rules'
import { error, success, treeDataTranslate } from '@core/utils/utils'
import { GetVerifyCode, GetPhoneSmsVerifyCode } from '@/api/system/user/user'
import { UploadFile } from '@/api/system/system'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    theLogo,
    ValidationProvider,
    ValidationObserver,
    BFormFile,
  },
  mixins: [togglePasswordVisibility],
  data () {
    return {
      loading: false,
      remember: true,
      imgSrc: '',
      password: '',
      verifyCode: '',
      verifyCodeId: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      mobile,
      isRegister: false,
      account: '',
      registerPswd: '',
      name: '',
      phoneNumber: '',
      phoneCode: '',
      corpname: '',
      corpEmail: '',
      address: '',
      codeFlag: false,
      codeText: '获取验证码',
      rePassword: '',
      errorsPwd: '',
    }
  },
  computed: {
    passwordToggleIcon () {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl () {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created () {
    this.getVerifyCode()
  },
  methods: {
    rePasswordBlur () {
      if (!this.rePassword) {
        this.errorsPwd = '请重新输入密码'
        return
      }
      if (this.rePassword !== this.registerPswd) {
        this.errorsPwd = '密码输入与原密码不符'
        return
      }
      this.errorsPwd = ''
    },
    getVerifyCode () {
      GetVerifyCode().then(res => {
        this.imgSrc = res.data.data.verifyCode
        this.verifyCodeId = res.data.data.verifyCodeId
      })
    },
    validationForm () {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.loading = true
          useJwt.login({
            email: this.userEmail,
            password: this.password,
            verifyCode: this.verifyCode,
            verifyCodeId: this.verifyCodeId,
          }).then(response => {
            this.loading = false
            if (response.data.code === 0) {
              const { token, user } = response.data.data
              const resourceLists = user.resourceList
              if (resourceLists != null) {
                // 保存按钮权限
                const buttonPerms = []
                resourceLists.forEach(r => {
                  if (r.type == 3) {
                    buttonPerms.push(r)
                  }
                })
                const leftNavPath = [
                  'policy-organization',
                  'policy',
                  'statement',
                  'statement-release',
                  'regime',
                  'dataActivity',
                  'asset',
                  'privacy',
                  'dataFound',
                  'dataAtlas',
                  'compliance-list',
                  'compliance-analysis',
                  'pia',
                  'template',
                  'risk',
                  'cpm-channel',
                  'cpm-statistic',
                  'cookies-list',
                  'cookies-template',
                  'cookies-website',
                  'thirdPartCompliance',
                  'privacyDesign',
                  'emergency',
                  'enterprise',
                  'product',
                  'role',
                  'thridParty',
                  'user',
                  'organization',
                  'log',
                  'notify',
                  'smtp',
                  'resource',
                ]
                const resourceList = treeDataTranslate(user.resourceList)
                const excludeNav = ['文档管理', '工作台']
                const navMenus = []
                resourceList.forEach(r => {
                  const children = []
                  if (r.children) {
                    r.children.forEach(c => {
                      if (c.type === 1) {
                        if (leftNavPath.indexOf(c.component) != -1) {
                          children.push({
                            title: c.name,
                            route: c.component,
                          })
                        }
                      }
                    })
                  }
                  const itemObj = {
                    title: r.name,
                    icon: r.icon,
                  }
                  if (children.length > 0) {
                    itemObj.children = children
                  } else {
                    itemObj.route = r.component
                  }
                  if (r.type != 4) {
                    if (itemObj.route) {
                      if (leftNavPath.indexOf(itemObj.route) != -1) {
                        navMenus.push(itemObj)
                      }
                    } else if (excludeNav.indexOf(itemObj.title) == -1) {
                      navMenus.push(itemObj)
                    }
                  }
                })
                user.navMenuItems = navMenus
                user.buttonPerms = buttonPerms
                useJwt.setToken(token)
                // if (this.remember) localStorage.setItem('userData', JSON.stringify(user))
                localStorage.setItem('userData', JSON.stringify(user))

                // this.$ability.update(user.ability)
                this.$router.replace({ name: 'workbench' }).then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `欢迎 ${user.actualName || user.email}`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: '登录成功',
                    },
                  })
                })
              } else {
                error('账号无权限,请联系管理员')
              }
            } else {
              this.getVerifyCode()
              error(response.data.msg)
            }
          })
        }
      })
    },
    getPhoneCode () {
      if (!this.phoneNumber.trim()) {
        document.getElementById('register-phone').focus()
        document.getElementById('register-phone').blur()
        return
      }
      if (this.codeFlag) return
      this.codeFlag = true
      let codeTime = 60
      this.codeText = `重新发送(${codeTime})`
      let timer = setInterval(() => {
        this.codeText = `重新发送(${codeTime})`
        codeTime--
        if (codeTime <= 0) {
          this.codeFlag = false
          this.codeText = '获取验证码'
          clearInterval(timer)
        }
      }, 1000);
      GetPhoneSmsVerifyCode({
        phoneNumber: this.phoneNumber
      }).then(response => {
        if (response.data.code !== 0) {
          this.codeFlag = false
          this.codeText = '获取验证码'
          clearInterval(timer)
        }
      })
    },
    validRegisterForm () {
      this.rePasswordBlur();
      this.$refs.registerValidation.validate().then(res => {
        if (res && !this.errorsPwd) {
          useJwt.register({
            account: this.account,
            email: this.corpEmail,
            enterpriseName: this.corpname,
            name: this.name,
            officeAddress: this.address,
            password: this.registerPswd,
            phoneNumber: this.phoneNumber,
            verifyCode: this.phoneCode,
          }).then(response => {
            if (response.data.code === 0) {
              this.isRegister = false
              success('企业注册成功，请登录！')
              this.account = ''
              this.corpEmail = ''
              this.corpname = ''
              this.name = ''
              this.address = ''
              this.registerPswd = ''
              this.phoneNumber = ''
              this.phoneCode = ''
              this.rePassword = ''
            } else {
              error(response.data.msg ? response.data.msg : '注册失败')
            }
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.verifycode {
  display: inline-block;
  width: 200px;
  height: 40px;
  border: 1px solid;
  border-radius: 4px;
  text-align: center;
  line-height: 40px;
  font-size: 24px;
  letter-spacing: 3px;
  margin-left: 10px;
  cursor: pointer;
}
.required-group {
  position: absolute;
  top: 8px;
  left: 0;
  color: #ff0000;
}
.goLogin {
  position: absolute;
  bottom: 0;
  right: 0;
}
.logo {
  width: 100px;
  height: auto;
  vertical-align: bottom;
}
.loginBox-enter,
.registerBox-leave-to {
  opacity: 0;
  transform: translateY(-100%);
}
.loginBox-leave-to,
.registerBox-enter {
  opacity: 0;
  transform: translateY(100%);
}
.loginBox-enter-active,
.loginBox-active,
.registerBox-enter-active,
.registerBox-leave-active {
  transition: 1.5s;
}
</style>
